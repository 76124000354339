import * as Sentry from '@sentry/browser'
import React, { Component, ErrorInfo, ReactNode } from 'react'

interface ErrorBoundaryState {
  error: Error | null
  errorInfo: any | null
  hasError: boolean
}

interface ErrorBoundaryProps {
  children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null, errorInfo: null, hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo, hasError: true })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, (errorInfo as any)[key])
      })
    })
    Sentry.captureException(error)
  }

  shouldComponentUpdate(nextProps: ErrorBoundaryProps, nextState: ErrorBoundaryState): boolean {
    return nextProps.children !== this.props.children || nextState.error !== this.state.error
  }

  render() {
    const { error, errorInfo } = this.state
    if (error) {
      const { name, message, stack } = error
      const { componentStack } = errorInfo
      return (
        <section className='section container p-10'>
          <div>
            <p>Uh oh, something went wrong while playing this announcement.</p>
            <p>If you use an ad blocking system, please disable this and try to reproduce the error.</p>
            <p>
              If this happens again, <a>open a GitHub issue</a>, and quote event ID{' '}
              <code className='text-red-600'>
                ${name}: ${message}
              </code>{' '}
              <strong>and</strong> provide the detailed error information below.
            </p>
            <details style={{ margin: '16px 0' }}>
              <summary>Detailed info</summary>
              <textarea
                className='my-10 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-red-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-red-600 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                style={{ width: '100%' }}
                rows={20}
                readOnly
                value={`${name}: ${message}\n\n${stack || '<no stacktrace>'}`}
              />
              <textarea
                className='my-10 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-red-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-red-600 dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                style={{ width: '100%' }}
                rows={25}
                readOnly
                value={componentStack}
              />
            </details>
            <p>{/* <button onClick={() => resetError()}>Try again</button> */}</p>
          </div>
        </section>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
